import { API_MEMBERS_TRACKING, API_METHOD_GET } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const GetAllStaffTracking = async () => {
    const method: Method = API_METHOD_GET;
    const url = API_MEMBERS_TRACKING;

    try {
        const response = await apiCall({
            method,
            url,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

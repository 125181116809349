import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HomeComponentState {
    currentComponent: "map" | "tracking" | "calendar";
}

const initialState: HomeComponentState = {
    currentComponent: 'map',
};

const homeComponentSlice = createSlice({
    name: 'home-component',
    initialState,
    reducers: {
        setComponent: (state, action: PayloadAction<"map" | "tracking" | "calendar">) => {
            state.currentComponent = action.payload;
        },
    },
});

export const { setComponent } = homeComponentSlice.actions;
export default homeComponentSlice.reducer;

import { AppModules } from '@/constants/AppModules';
import { PermissionActions } from '@/constants/PermissionActions';

export const checkCan: (
  permissionList: string[] | undefined,
  permission: string,
) => boolean = (permissionList: string[] | undefined, permission: string) => {
  if (permissionList === undefined || permissionList.length === 0) {
    return false;
  }

  return permissionList.includes(permission);
};

export const hasPermission = (
  permission: string,
  userPermissions: string[],
  permissionModule?: string,
) => {
  // If permissionModule is undefined or empty, always return true
  if (!permissionModule) {
    return true; // No module means always allow
  }
  return userPermissions.includes(permission);
};

{
  /*======================== Request Permissions Start========================*/
}
export const permissionCanReadRequest = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.REQUESTS}.${PermissionActions.Read}`,
  ) ?? false;

export const permissionCanCreateRequest = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.REQUESTS}.${PermissionActions.Create}`,
  ) ?? false;

export const permissionCanUpdateRequest = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.REQUESTS}.${PermissionActions.Update}`,
  ) ?? false;

{
  /*======================== Request Permissions End========================*/
}

{
  /*======================== Client Permissions Start========================*/
}
export const permissionCanReadClient = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.CLIENTS}.${PermissionActions.Read}`,
  ) ?? false;

export const permissionCanCreateClient = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.CLIENTS}.${PermissionActions.Create}`,
  ) ?? false;

export const permissionCanUpdateClient = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.CLIENTS}.${PermissionActions.Update}`,
  ) ?? false;

export const permissionCanDeleteClient = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.CLIENTS}.${PermissionActions.Delete}`,
  ) ?? false;

{
  /*======================== Client Permissions End========================*/
}

{
  /*======================== Quote Permissions Start========================*/
}
export const permissionCanReadQuote = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.QUOTES}.${PermissionActions.Read}`,
  ) ?? false;

export const permissionCanCreateQuote = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.QUOTES}.${PermissionActions.Create}`,
  ) ?? false;

export const permissionCanUpdateQuote = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.QUOTES}.${PermissionActions.Update}`,
  ) ?? false;

export const permissionCanDeleteQuote = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.QUOTES}.${PermissionActions.Delete}`,
  ) ?? false;

{
  /*======================== Quote Permissions End========================*/
}

{
  /*======================== Job Permissions Start========================*/
}
export const permissionCanReadJob = (user: { permissions?: string[] } | null) =>
  user?.permissions?.includes(`${AppModules.JOBS}.${PermissionActions.Read}`) ??
  false;

export const permissionCanCreateJob = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.JOBS}.${PermissionActions.Create}`,
  ) ?? false;

export const permissionCanUpdateJob = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.JOBS}.${PermissionActions.Update}`,
  ) ?? false;

export const permissionCanDeleteJob = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.JOBS}.${PermissionActions.Delete}`,
  ) ?? false;

{
  /*======================== Job Permissions End========================*/
}

{
  /*======================== Purchase Order Permissions Start========================*/
}
export const permissionCanReadPurchaseOrder = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.PURCHASE_ORDERS}.${PermissionActions.Read}`,
  ) ?? false;

export const permissionCanCreatePurchaseOrder = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.PURCHASE_ORDERS}.${PermissionActions.Create}`,
  ) ?? false;

export const permissionCanUpdatePurchaseOrder = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.PURCHASE_ORDERS}.${PermissionActions.Update}`,
  ) ?? false;

export const permissionCanDeletePurchaseOrder = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.PURCHASE_ORDERS}.${PermissionActions.Delete}`,
  ) ?? false;

{
  /*======================== Purchase Order Permissions End========================*/
}

{
  /*======================== Invoice Permissions Start========================*/
}
export const permissionCanReadInvoice = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.INVOICES}.${PermissionActions.Read}`,
  ) ?? false;

export const permissionCanCreateInvoice = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.INVOICES}.${PermissionActions.Create}`,
  ) ?? false;

export const permissionCanUpdateInvoice = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.INVOICES}.${PermissionActions.Update}`,
  ) ?? false;

export const permissionCanDeleteInvoice = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.INVOICES}.${PermissionActions.Delete}`,
  ) ?? false;

{
  /*======================== Invoice Permissions End========================*/
}

{
  /*======================== Setting Permissions Start========================*/
}
export const permissionCanReadSetting = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.SETTINGS}.${PermissionActions.Read}`,
  ) ?? false;

export const permissionCanCreateSetting = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.SETTINGS}.${PermissionActions.Create}`,
  ) ?? false;

export const permissionCanUpdateSetting = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.SETTINGS}.${PermissionActions.Update}`,
  ) ?? false;

export const permissionCanDeleteSetting = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.SETTINGS}.${PermissionActions.Delete}`,
  ) ?? false;

{
  /*======================== Invoice Permissions End========================*/
}

{
  /*======================== Team members Permissions Start========================*/
}
export const permissionCanReadTeamMembers = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.TEAM_MEMBERS}.${PermissionActions.Read}`,
  ) ?? false;

export const permissionCanCreateTeamMembers = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.TEAM_MEMBERS}.${PermissionActions.Create}`,
  ) ?? false;

export const permissionCanUpdateTeamMembers = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.TEAM_MEMBERS}.${PermissionActions.Update}`,
  ) ?? false;

export const permissionCanDeleteTeamMembers = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.TEAM_MEMBERS}.${PermissionActions.Delete}`,
  ) ?? false;
{
  /*======================== Team members Permissions End========================*/
}

{
  /*======================== Suppliers Permissions Start========================*/
}
export const permissionCanReadSuppliers = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.SUPPLIERS}.${PermissionActions.Read}`,
  ) ?? false;

export const permissionCanCreateSuppliers = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.SUPPLIERS}.${PermissionActions.Create}`,
  ) ?? false;

export const permissionCanUpdateSuppliers = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.SUPPLIERS}.${PermissionActions.Update}`,
  ) ?? false;

export const permissionCanDeleteSuppliers = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.SUPPLIERS}.${PermissionActions.Delete}`,
  ) ?? false;
{
  /*======================== Supplier Permissions End========================*/
}

{
  /*======================== Enquiry Permissions Start========================*/
}
export const permissionCanReadEnquiry = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.REQUESTS}.${PermissionActions.Read}`,
  ) ?? false;

export const permissionCanCreateEnquiry = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.REQUESTS}.${PermissionActions.Create}`,
  ) ?? false;

export const permissionCanUpdateEnquiry = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.REQUESTS}.${PermissionActions.Update}`,
  ) ?? false;

export const permissionCanDeleteEnquiry = (
  user: { permissions?: string[] } | null,
) =>
  user?.permissions?.includes(
    `${AppModules.REQUESTS}.${PermissionActions.Delete}`,
  ) ?? false;
{
  /*======================== Enquiry Permissions End========================*/
}

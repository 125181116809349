export enum AppModules {
  REQUESTS = 'Requests',
  CLIENTS = 'Customers',
  QUOTES = 'Quotes',
  SUPPLIERS = 'Suppliers',
  PRODUCTS_SERVICES = 'Products_Services',
  INVENTORIES = 'Inventory',
  JOBS = 'Jobs',
  PURCHASE_ORDERS = 'Purchase_Orders',
  TEAM_MEMBERS = 'Team_Members',
  TIMESHEETS = 'Timesheets',
  EXPENSES = 'Expenses',
  INVOICES = 'Invoicing',
  REPORTS = 'Reports',
  SETTINGS = 'Settings',
}

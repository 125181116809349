import axiosInstance from './axiosInstance';
import axios, { AxiosRequestConfig, Method } from 'axios';
import {
  API_AUTH_REFRESH_TOKEN_URL,
  API_METHOD_POST,
} from '@/constants/apiConstants';
import { toast } from 'react-toastify';

interface ApiCallOptions {
  method: Method;
  url: string;
  data?: any;
  config?: AxiosRequestConfig;
  headers?: Record<string, string>;
  token?: string; // Optional token parameter
  refresh_token?: string;
}

let failedQueue: any[] = [];

/**
 * Processes a queue of failed API requests.
 *
 * This function is typically called after attempting to refresh an access token.
 * It iterates over the `failedQueue` array, which contains promises that were
 * waiting for the token refresh to complete. If a new token is available, the
 * queued promises are resolved with this token. If an error occurred during the
 * token refresh, the queued promises are rejected with the error.
 *
 * @param error - The error that occurred during the token refresh, if any. If
 *                no error occurred, this will be `null`.
 * @param token - The new access token, if the token refresh was successful. If
 *                the refresh failed, this will be `null`.
 */
const processQueue = (error: any, token: string | null = null) => {
  // Iterate over each item (promise) in the failedQueue
  failedQueue.forEach((prom) => {
    if (token) {
      // If a new token is available, resolve the promise with the new token
      prom.resolve(token);
    } else {
      // If an error occurred during the token refresh, reject the promise with the error
      prom.reject(error);
    }
  });
  // Clear the failedQueue array after processing to ensure no residual promises are left
  failedQueue = [];
};

const refreshAccessToken = async (token: string) => {
  // const dispatch: AppDispatch = useDispatch<AppDispatch>();
  try {
    const response = await axiosInstance({
      method: API_METHOD_POST,
      url: API_AUTH_REFRESH_TOKEN_URL,
      data: '',
      headers: {
        ...axiosInstance.defaults.headers.common, // Include default headers
        ...(token && { Authorization: `Bearer ${token}` }), // Add Bearer token if provided
      },
      // ...config, // Additional Axios config options can be passed here
    });
    const newAccessToken = response.data?.data?.access_token?.token;
    const newRefreshToken = response.data?.data?.refresh_token?.token;

    // Get token from Local Storage
    localStorage.setItem('ACCESS_TOKEN', newAccessToken);
    localStorage.setItem('REFRESH_TOKEN', newRefreshToken);

    // Todo
    // if (newAccessToken && newRefreshToken) {
    //   dispatch(
    //     login({
    //       user: response.data.data.user, // Adjust based on your API response
    //       access_token: newAccessToken,
    //       refresh_token: newRefreshToken,
    //       subscription: response.data.data?.subscription,
    //     }),
    //   );
    // }

    return newAccessToken; // Return the data from the response
  } catch (error) {
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');

    toast.error('Your session has expired. Please log in again to continue.');

    window.location.href = '/login';

    console.error('Token refresh error:', error);
    return null;
  }
};

export const apiCall = async ({
  method,
  url,
  data = null,
  config = {},
  headers = {},
}: ApiCallOptions): Promise<any> => {
  try {
    const token = localStorage.getItem('ACCESS_TOKEN');

    // Make the API request using the provided method, URL, data, and headers
    const response = await axiosInstance({
      method,
      url,
      data,
      headers: {
        ...axiosInstance.defaults.headers.common, // Include default headers
        ...headers, // Merge with additional headers
        ...(token && { Authorization: `Bearer ${token}` }), // Add Bearer token to headers if provided
      },
      ...config, // Additional Axios config options can be passed here
    });
    return response.data; // Return the data from the response
  } catch (error: any) {
    // Save the original request details for potential retry
    const originalRequest = {
      method,
      url,
      data,
      config,
      headers,
    };

    // Check if the error is an AxiosError and if it was caused by an unauthenticated request (401)
    if (
      (axios.isAxiosError(error) && error.response?.status === 401) ||
      (axios.isAxiosError(error) && error.response?.status === 402) ||
      (axios.isAxiosError(error) && error.response?.status === 403)
    ) {
      if (error.response?.status === 402) {
        // hard redirect to subscription page
        window.location.href = '/subscription';
      }

      if (error.response?.status === 403) {
        // hard redirect to subscription page
        window.location.href = '/403';
      }

      // If a token refresh is not already in progress, start it
      if (error.response?.status === 401) {
        try {
          // Attempt to refresh the access token using the refresh token
          let newAccessToken;

          const refresh_token = localStorage.getItem('REFRESH_TOKEN');

          if (refresh_token) {
            newAccessToken = await refreshAccessToken(refresh_token);
          }

          // Process any requests that were queued while waiting for the token refresh
          processQueue(null, newAccessToken);

          // Retry the original API call with the new access token, if successful
          if (newAccessToken) {
            return apiCall({
              ...originalRequest,
              token: newAccessToken,
            });
          }
        } catch (refreshError) {
          // If refreshing the token fails, process the queue with an error
          processQueue(refreshError, null);
          throw refreshError; // Throw the error to be handled by the calling code
        }
      } else {
        // If a token refresh is already in progress, queue the current request
        return new Promise((resolve, reject) => {
          failedQueue.push({
            resolve: (token: string) => {
              // Once the token is refreshed, retry the original API call
              resolve(
                apiCall({
                  ...originalRequest,
                  token,
                }),
              );
            },
            reject: (err: any) => reject(err),
          });
        });
      }
    } else {
      if (error) {
        // throw error;
        // all system Set form-level error
        if (error) {
          toast.error(error?.response?.data?.message);
        }
        throw error;
      } else {
        // Handle non-Axios errors
        console.error('Unexpected error ');
        // throw new Error('Unexpected error occurred');
        console.error('An unexpected error occurred. Please try again.');
      }
    }
  }
};

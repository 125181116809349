import React, { useState } from 'react';
import { Button } from '@nextui-org/react';

// Icons
import { CalendarIcon } from '../../../../public/assets/icons/CalendarIcon';
import { MapIcon } from '../../../../public/assets/icons/MapIcon';

interface ToggleTabsProps {
  id: string;
  onTabChange: (activeTab: 'map' | 'tracking' | 'calendar') => void;
}

const ToggleTabs: React.FC<ToggleTabsProps> = ({ id, onTabChange }) => {
  const [activeTab, setActiveTab] = useState<'map' | 'tracking' | 'calendar'>(
    'map',
  );

  const handleTabChange = (tab: 'map' | 'tracking' | 'calendar') => {
    setActiveTab(tab);
    onTabChange(tab); // Notify parent of the change
  };

  return (
    <div
      id={id}
      className="flex h-auto sm:h-10 items-center flex-col w-full sm:flex-row bg-white border-2 border-solid border-light-base-primary rounded-md sm:w-fit"
    >
      <Button
        id={`${id}-button-map`}
        size="md"
        radius="sm"
        className={`flex items-center justify-center w-full lg:w-[141px] rounded-none sm:rounded-l-md transition-all ${
          activeTab === 'map'
            ? 'bg-light-base-primary text-white'
            : 'bg-transparent text-light-base-primary'
        }`}
        onClick={() => handleTabChange('map')}
      >
        <MapIcon color={activeTab === 'map' ? 'white' : '#34C100'} />
        Map
      </Button>
      <Button
        id={`${id}-button-tracking`}
        size="md"
        radius="sm"
        className={`flex items-center justify-center w-full lg:w-[141px] border-y-2 sm:border-x-2 border-solid border-light-base-primary rounded-none transition-all ${
          activeTab === 'tracking'
            ? 'bg-light-base-primary text-white'
            : 'bg-transparent text-light-base-primary'
        }`}
        onClick={() => handleTabChange('tracking')}
      >
        <MapIcon color={activeTab === 'tracking' ? 'white' : '#34C100'} />
        Tracking
      </Button>
      <Button
        id={`${id}-button-calendar`}
        size="md"
        radius="sm"
        className={`flex items-center justify-center w-full lg:w-[141px] rounded-none sm:rounded-r-md transition-all ${
          activeTab === 'calendar'
            ? 'bg-light-base-primary text-white'
            : 'bg-transparent text-light-base-primary'
        }`}
        onClick={() => handleTabChange('calendar')}
      >
        <CalendarIcon color={activeTab === 'calendar' ? 'white' : '#34C100'} />
        Calendar
      </Button>
    </div>
  );
};

export default ToggleTabs;

const broadcast = new BroadcastChannel('quotes-sync');

export const sendBroadcast = (data: any) => {
  broadcast.postMessage(data);
};

export const listenBroadcast = (callback: (data: any) => void) => {
  broadcast.onmessage = (event) => callback(event.data);
};

export const closeBroadcast = () => {
  broadcast.close();
};

'use client';
import React, { useEffect, useState } from 'react';
import { Button } from '@nextui-org/react';
import { DateValue, today } from '@internationalized/date';
import { DatePicker } from '@nextui-org/date-picker';
import { useRouter } from 'next/navigation';

// Custom Components
import BaseButton from '@/components/button/BaseButton';
import TextInput from '@/components/input/TextInput';
import { SearchIcon } from '@/components/icons/search-icon';
import JobCard from '@/components/jobComponents/JobCard';
import MapComponent from '@/components/MapComponent';
import JobCalendar from '@/components/jobComponents/JobCalendar';
import ToggleTabs from '@/components/jobComponents/ToggleTabs';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/redux/store';
import { searchJobs } from '@/redux/thunk/jobScheduleThunk';
import { getJobs } from '@/redux/thunk/jobScheduleCalenderThunk';

// Icons
import { CalendarIcon } from '../../../public/assets/icons/CalendarIcon';
import { ResetIcon } from '@/components/icons/reset-icon';
import { UnavailableLocation } from '../../../public/assets/icons/UnavailableLocation';

// Custom Hooks
import useDebounce from '@/hooks/useDebounce';
import JobCardSkeleton from '@/components/skeleton/JobSchedule/JobCardSkeleton';
import MapSkeleton from '@/components/skeleton/JobSchedule/MapSkeleton';
import { PermissionActions } from '@/constants/PermissionActions';
import {
  canReadJob,
  permissionCanCreateJob,
  permissionCanReadJob,
  permissionCanReadTeamMembers,
} from '@/helpers/permissions';
import { setComponent } from '@/redux/slices/homeComponentSlice';
import { fetchTeamMembers, searchTeamMembers } from '@/redux/thunk/teamThunk';
import TeamMemberCard from '../team/TeamCard';
import TrackingMapComponent from '../MapComponent/Tracking';
import {
  fetchAllStaffTracking,
  fetchSingleStaffTracking,
} from '@/redux/thunk/staffTrackingThunk';
import { Team } from '@/helpers/types';
import { clearSingleStaffTracking } from '@/redux/slices/staffTrackingSingleSlice';
import { format, parseISO } from 'date-fns';
import { resetBreadcrumbs } from '@/redux/slices/breadcrumbSlice';

interface HomePageContentProps {
  id: string;
}

const HomePageContent: React.FC<HomePageContentProps> = ({ id }) => {
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();

  const { jobs } = useSelector((state: RootState) => state.jobSchedule);
  const { jobsForCalender } = useSelector(
    (state: RootState) => state.jobScheduleCalender,
  );

  // Extract user date and time format from Redux
  const { user, company } = useSelector((state: RootState) => state.auth);
  const { currentComponent } = useSelector(
    (state: RootState) => state.homeComponent,
  );
  const { team, status: teamMembersFetchingStatus } = useSelector(
    (state: RootState) => state.team,
  );
  const { allStaffTracking, status: allStaffFetchingStatus } = useSelector(
    (state: RootState) => state.staffAllTracking,
  );
  const { singleStaffTracking, status: singleStaffFetchingStatus } =
    useSelector((state: RootState) => state.staffSingleTracking);

  const permissions = {
    canReadJob: permissionCanReadJob(user),
    canCreateJob: permissionCanCreateJob(user),
    canReadTeamMembers: permissionCanReadTeamMembers(user),
  };

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);

  const [selectedTab, setSelectedTab] = useState<string>('map');
  const [selectedJob, setSelectedJob] = useState<any>(null);
  const [selectedTeamMember, setSelectedTeamMember] = useState<
    undefined | Team
  >(undefined);
  const [selectedDate, setSelectedDate] = useState<DateValue | null>(
    today('UTC'),
  );
  const [loading, setLoading] = useState(true);
  const [buttonWidth, setButtonWidth] = useState('auto');
  const [dataParams, setDataParams] = useState<{ start: string; end: string }>({
    start: '',
    end: '',
  });

  // **Show a message and DO NOT dispatch if user has no read permission**
  if (!permissions?.canReadJob) {
    return (
      <div className="flex h-[calc(100vh-272px)] items-center justify-center text-center text-gray-600">
        <p className="text-lg font-semibold">
          🚫 Access Denied: You do not have permission to view jobs.
        </p>
      </div>
    );
  }

  useEffect(() => {
    dispatch(resetBreadcrumbs());
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      searchJobs({
        searchTerm: debouncedSearchTerm,
        startDate: selectedDate,
        endDate: selectedDate,
      }),
    ).finally(() => setLoading(false));
  }, [debouncedSearchTerm, selectedDate, dispatch]);

  useEffect(() => {
    if (jobs?.length) {
      setSelectedJob(jobs[0]); // Set the first job as the selected job
    }
  }, [jobs]);

  useEffect(() => {
    if (dataParams?.start && dataParams?.end) {
      const formattedStartDate = format(
        parseISO(dataParams.start),
        'yyyy-MM-dd',
      );
      const formattedEndDate = format(parseISO(dataParams.end), 'yyyy-MM-dd');

      dispatch(
        getJobs({
          searchTerm: '',
          startDate: formattedStartDate, // ✅ Ensure YYYY-MM-DD format
          endDate: formattedEndDate, // ✅ Ensure YYYY-MM-DD format
        }),
      ).finally(() => setLoading(false));
    }
  }, [dataParams, dispatch]);

  // Tracking
  // fetch all team members
  useEffect(() => {
    if (currentComponent === 'tracking') {
      dispatch(fetchTeamMembers());
    }
  }, [dispatch, currentComponent]);

  // Set the first team member as the selected team member
  // useEffect(() => {
  //   if (team?.length) {
  //     setSelectedTeamMember(team[0])
  //   }
  // }, [team])

  // Search team members
  useEffect(() => {
    if (currentComponent === 'tracking') {
      dispatch(
        searchTeamMembers({
          searchTerm,
          selectedStatus: 100,
          pageSize: '100',
          pagination: 1,
          // sortBy: sortBy,
          // sortOrder: sortOrder,
        }),
      );
    }
  }, [searchTerm, currentComponent]);

  // fetch tracking data of all team members
  useEffect(() => {
    if (currentComponent === 'tracking') {
      dispatch(fetchAllStaffTracking()).unwrap();
    }
  }, [dispatch, currentComponent]);

  // fetch tracking data of single team member
  useEffect(() => {
    if (selectedTeamMember !== undefined && currentComponent === 'tracking') {
      dispatch(
        fetchSingleStaffTracking({ staffUuid: selectedTeamMember.uuid }),
      ).unwrap();
    }
  }, [selectedTeamMember]);

  // render the map
  useEffect(() => {
    if (currentComponent === 'tracking') {
      renderTrackingMap();
    }
  }, [selectedTeamMember, allStaffTracking]);

  // Dynamically change width based on screen size
  useEffect(() => {
    const updateButtonWidth = () => {
      if (window.innerWidth < 1081) {
        setButtonWidth('100%'); // Full width on small screens
      } else {
        setButtonWidth('141px'); // Fixed width on larger screens
      }
    };

    window.addEventListener('resize', updateButtonWidth);
    updateButtonWidth(); // Initial call to set the width
    return () => window.removeEventListener('resize', updateButtonWidth);
  }, []);

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (date: DateValue) => {
    setSelectedDate(date);
  };

  const handleReset = () => {
    setSearchTerm('');
    setSelectedDate(today('UTC'));
  };

  const handleJobCardClick = (job: any) => {
    setSelectedJob(job);
  };

  const handleMapLocationSelect = (job: any) => {
    setSelectedJob(job);
  };

  const handleAddNewJob = () => {
    router.push(`/jobs/new-job`);
  };

  const handleDataParamChange = (params: { start: string; end: string }) => {
    setDataParams({ start: params?.start, end: params?.end });
  };

  // Tracking
  const handleTeamMemberCardClick = (team: any) => {
    setSelectedTeamMember(team);
  };

  // Render taking map or messages based on the data from APIs
  const renderTrackingMap = () => {
    // Check if selectedTeamMember is undefined and at least one object in allStaffTracking does not have the 'latitude' property
    const hasLatitudeInAllStaff = allStaffTracking.some((obj) =>
      Object.prototype.hasOwnProperty.call(obj, 'latitude'),
    );
    if (selectedTeamMember === undefined && hasLatitudeInAllStaff === false) {
      return (
        <div className="flex h-full items-center flex-col justify-center text-center">
          <UnavailableLocation color="#6D788D" />
          <p>No Locations to display</p>
        </div>
      );

      // Check if selectedTeamMember is not undefined, but the first object in singleStaffTracking does not have the 'latitude' property
    } else if (
      selectedTeamMember !== undefined &&
      !(
        singleStaffTracking[0] &&
        Object.hasOwn(singleStaffTracking[0], 'latitude')
      )
    ) {
      return (
        <div className="flex h-full items-center flex-col justify-center text-center">
          <UnavailableLocation color="#6D788D" />
          <p>No Locations to display</p>
        </div>
      );

      // Otherwise, display the map
    } else {
      return (
        <TrackingMapComponent
          id={`${id}-tackingMapComponent`}
          staff={allStaffTracking}
          selectedStaff={singleStaffTracking[0]}
          // onLocationSelect={(job) => handleMapLocationSelect(job)}
          // company={company}
        />
      );
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-272px)]">
      <div className="w-full flex flex-col md:flex-row gap-5 h-full">
        {/* Left section */}
        {currentComponent === 'map' || currentComponent === 'calendar' ? (
          <div className="w-full md:w-3/12 h-full flex flex-col">
            {/* Header and Search */}
            <div className="flex flex-col lg:flex-row justify-between">
              {permissions.canReadJob && (
                <div className="flex items-center text-body-lg pb-2 lg:pb-0 lg:text-body-xl">
                  <div className="pr-2 lg:pr-0">Current Jobs</div>
                </div>
              )}

              {permissions.canCreateJob && (
                <div>
                  <BaseButton
                    id={`${id}-baseButton-addNewJob`}
                    variant="bordered"
                    color="primary"
                    width={buttonWidth}
                    onClick={handleAddNewJob}
                    size="md"
                  >
                    <span className="font-bold">+</span> Job
                  </BaseButton>
                </div>
              )}
            </div>

            {permissions.canReadJob && (
              <>
                <div className="flex w-full pt-5">
                  <div className="flex w-full gap-5">
                    <div className="flex-1">
                      <TextInput
                        id={`${id}-textInput-searchJob`}
                        value={searchTerm}
                        type="text"
                        variant="bordered"
                        label=""
                        radius="sm"
                        style={{ width: '100%' }}
                        placeholder="Search Jobs"
                        labelPlacement="outside"
                        onChange={(e) => handleSearch(e)}
                        endContent={<SearchIcon />}
                      />
                    </div>
                    <div>
                      <Button
                        id={`${id}-baseButton-reset`}
                        isIconOnly
                        variant="bordered"
                        radius="sm"
                        aria-label="Clear Date Range"
                        className="w-10 h-10 border-[#E0E0E0]"
                        onClick={handleReset}
                      >
                        <ResetIcon color="#6D788D"></ResetIcon>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="mt-5 w-full">
                  <DatePicker
                    id={`${id}-datePicker`}
                    value={selectedDate}
                    variant={'bordered'}
                    aria-placeholder="Date"
                    className="w-full flex-1"
                    onChange={handleDateChange}
                    selectorIcon={<CalendarIcon color="#6D788D" />}
                    labelPlacement="outside"
                    fullWidth={true}
                    color="default"
                    size="md"
                    radius="sm"
                  />
                </div>
                {/* Scrollable Job Cards */}
                <div className="mt-5 overflow-y-auto h-full pr-3">
                  {loading ? (
                    <div className="pr-1">
                      <JobCardSkeleton id={`${id}-datePicker`} />
                    </div>
                  ) : jobs?.length ? (
                    jobs?.map((job) => (
                      <div key={job?.uuid} className="pb-5">
                        <JobCard
                          id={`${id}-jobCard-${job?.uuid}`}
                          job={job}
                          selectedJobUUID={selectedJob?.uuid}
                          onClick={(job) => handleJobCardClick(job)}
                          company={company}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="flex h-full items-center justify-center text-center">
                      <p>No Jobs</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="w-full md:w-3/12 h-full flex flex-col">
            {/* Header and Search */}
            <div className="flex flex-col lg:flex-row justify-between">
              {permissions.canReadTeamMembers && (
                <div className="flex items-center text-body-lg pb-2 lg:pb-0 lg:text-body-xl">
                  <div className="pr-2 lg:pr-0 min-h-[40px]">
                    Location Tracking
                  </div>
                </div>
              )}

              {/* {permissions.canCreateJob && (
                <div>
                  <BaseButton
                    id={`${id}-baseButton-addNewJob`}
                    variant="bordered"
                    color="primary"
                    width={buttonWidth}
                    onClick={handleAddNewJob}
                    size="md"
                  >
                    <span className="font-bold">+</span> Job
                  </BaseButton>
                </div>
              )} */}
            </div>

            {permissions.canReadTeamMembers && (
              <>
                <div className="flex w-full pt-5">
                  <div className="flex w-full gap-5">
                    <div className="flex-1">
                      <TextInput
                        id={`${id}-textInput-searchTeamMember`}
                        value={searchTerm}
                        type="text"
                        variant="bordered"
                        label=""
                        radius="sm"
                        style={{ width: '100%' }}
                        placeholder="Search Team Members"
                        labelPlacement="outside"
                        onChange={(e) => handleSearch(e)}
                        endContent={<SearchIcon />}
                      />
                    </div>
                    <div>
                      <Button
                        id={`${id}-baseButton-reset`}
                        isIconOnly
                        variant="bordered"
                        radius="sm"
                        aria-label="Clear Date Range"
                        className="w-10 h-10 border-[#E0E0E0]"
                        onClick={() => {
                          handleReset();
                          setSelectedTeamMember(undefined);
                          dispatch(clearSingleStaffTracking());
                        }}
                      >
                        <ResetIcon color="#6D788D"></ResetIcon>
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-5 w-full">
                  <DatePicker
                    id={`${id}-datePicker`}
                    value={selectedDate}
                    variant={'bordered'}
                    aria-placeholder="Date"
                    className="w-full flex-1"
                    onChange={handleDateChange}
                    selectorIcon={<CalendarIcon color="#6D788D" />}
                    labelPlacement="outside"
                    fullWidth={true}
                    color="default"
                    size="md"
                    radius="sm"
                  />
                </div> */}
                {/* Scrollable team member cards */}
                <div className="mt-5 overflow-y-auto h-full pr-3">
                  {teamMembersFetchingStatus === 'loading' ? (
                    <div className="pr-1">
                      <JobCardSkeleton id={`${id}-datePicker`} />
                    </div>
                  ) : team?.length ? (
                    team?.map((team) => (
                      <div key={team?.uuid} className="pb-5">
                        <TeamMemberCard
                          id={`${id}-teamMemberCard-${team?.uuid}`}
                          teamMember={team}
                          selectedTeamMemberUUID={selectedTeamMember?.uuid}
                          onClick={(team) => handleTeamMemberCardClick(team)}
                          // company={company}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="flex h-full items-center justify-center text-center">
                      <p>No Team Members</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}

        {/* Right section */}
        <div className="w-full md:w-9/12 h-full flex flex-col">
          {permissions.canReadJob && (
            <>
              <div className="pb-5">
                <div className="flex w-full flex-col items-end md:pr-5">
                  <ToggleTabs
                    id={`${id}-toggleTabs`}
                    onTabChange={(key: 'map' | 'tracking' | 'calendar') => {
                      setSelectedTab(key);
                      dispatch(setComponent(key));
                    }}
                  />
                </div>
              </div>
              <div className="border-2 border-light-secondary-100 rounded-lg overflow-hidden flex-1">
                {selectedTab === 'map' ? (
                  <div className="h-full">
                    {loading ? (
                      <div>
                        <MapSkeleton id={`${id}-mapSkeleton`} />
                      </div>
                    ) : jobs?.length ? (
                      <MapComponent
                        id={`${id}-mapComponent`}
                        jobs={jobs}
                        selectedJob={selectedJob}
                        onLocationSelect={(job) => handleMapLocationSelect(job)}
                        company={company}
                      />
                    ) : (
                      <div className="flex h-full items-center flex-col justify-center text-center">
                        <UnavailableLocation color="#6D788D" />
                        <p>No Locations to display</p>
                      </div>
                    )}
                  </div>
                ) : selectedTab === 'calendar' ? (
                  <div>
                    <JobCalendar
                      id={`${id}-jobCalender`}
                      jobs={jobsForCalender}
                      onChangeDataParams={handleDataParamChange}
                      firstDayOfTheWeek={company?.first_day_of_week?.id}
                    />
                  </div>
                ) : (
                  <div className="h-full">
                    {singleStaffFetchingStatus === 'loading' ||
                    allStaffFetchingStatus === 'loading' ? (
                      <div>
                        <MapSkeleton id={`${id}-mapSkeleton`} />
                      </div>
                    ) : (
                      renderTrackingMap()
                    )}
                    {/* {singleStaffFetchingStatus === 'loading' || allStaffFetchingStatus === 'loading' ? (
                      <div>
                        <MapSkeleton id={`${id}-mapSkeleton`} />
                      </div>
                    ) : ((singleStaffTracking[0] && Object.hasOwn(singleStaffTracking[0], 'latitude'))) || allStaffTracking.some(obj => Object.prototype.hasOwnProperty.call(obj, 'latitude')) ? (
                      <TrackingMapComponent
                        id={`${id}-tackingMapComponent`}
                        staff={allStaffTracking}
                        selectedStaff={singleStaffTracking[0]}
                      // onLocationSelect={(job) => handleMapLocationSelect(job)}
                      // company={company}
                      />
                    ) : (
                      <div className="flex h-full items-center flex-col justify-center text-center">
                        <UnavailableLocation color="#6D788D" />
                        <p>No Locations to display</p>
                      </div>
                    )} */}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePageContent;

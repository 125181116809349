export enum PermissionActions {
  Read = 'Read',
  Update = 'Update',
  Delete = 'Delete',
  Create = 'Create',
  Approve = 'Approve',
  View_Van_Inventory = 'View Van Inventories',
  View_Main_Inventory = 'View Main Inventory',
  Adjust_Inventory = 'Adjust Inventory',
}

import React from 'react';
import { Chip } from '@nextui-org/react';

interface JobStatusIndicatorProps {
  id: string;
  status: 1 | 2 | 3 | 4 | 5 | number | string; // Numeric representation for statuses
}

const JobStatusIndicator: React.FC<JobStatusIndicatorProps> = ({
  id,
  status,
}) => {
  const statusMap: Record<
    JobStatusIndicatorProps['status'],
    { className: string; text: string }
  > = {
    1: {
      className: 'bg-[#EDEFF1] text-[#6D788D]',
      text: 'Draft',
    },
    2: {
      className: 'bg-[#FFF6E5] text-[#FFA500]',
      text: 'In Progress',
    },
    3: {
      className: 'bg-[#EEFBE5] text-[#34C100]',
      text: 'Completed',
    },
    4: {
      className: 'bg-[#FFE9E9] text-[#F90004]',
      text: 'Closed',
    },
    5: {
      className: 'bg-[#FFE9E9] text-[#F90004]',
      text: 'Cancelled',
    },
  };

  const statusDetails = statusMap[status];

  if (!statusDetails) {
    return null; // Return nothing if the status is invalid
  }

  const { className, text } = statusDetails;

  return (
    <Chip id={id} className={className} radius="sm" variant="flat" size="sm">
      {text}
    </Chip>
  );
};

export default JobStatusIndicator;

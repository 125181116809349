'use client';

import React, { useEffect, useState } from 'react';
import { DateSelectArg, DatesSetArg, EventClickArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useRouter } from 'next/navigation';

// Custom Styles
import Styles from './jobCalender.module.css';

// Types
import { Job } from '@/helpers/types';

interface JobCalendarProps {
  id: string;
  jobs: Job[];
  onChangeDataParams: (params: { start: string; end: string }) => void;
  firstDayOfTheWeek: number;
}

interface Event {
  uuid: string;
  title: string;
  job_number: string;
  start_date: string;
  end_date: string;
  start: string;
  end: string;
  type: string;
  status: string;
  total_price: string;
  recurring_type: string;
  customer_name: string;
  customer_site_name: string;
  customer_site_address: string;
  created_at: string;
  backgroundColor: string;
  className: string;
  textColor: string;
}

const JobCalendar: React.FC<JobCalendarProps> = ({
  id,
  jobs,
  onChangeDataParams,
  firstDayOfTheWeek,
}) => {
  const router = useRouter();

  const [newEventTitle, setNewEventTitle] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<DateSelectArg | null>(null);
  const [events, setEvents] = useState<Event[]>([]);

  const formatTime = (time: any) => {
    if (!time) return '00:00:00'; // Default to midnight if no time is provided
    return time?.length === 5 ? `${time}:00` : time; // Append ":00" only if seconds are missing
  };

  useEffect(() => {
    const transformedJobs = jobs?.map((job: any) => ({
      ...job,
      title: `#${job?.job_number} - ${job?.title}`,
      start: `${job?.start_date}T${formatTime(job?.start_time)}`, // ✅ Ensures proper format
      end: `${job?.end_date ? job?.end_date : job?.start_date}T${formatTime(job?.end_time || '23:59:00')}`, // ✅ Handles missing end date & time
      backgroundColor: handleEventLabelBG(job?.status),
      className: Styles.customEventStyle,
      textColor: handleEventLabelText(job?.status),
      allDay: false, // ✅ Ensures time-based event rendering
      display: 'block',
    }));

    setEvents(transformedJobs);
  }, [jobs]);

  const handleEventLabelBG = (status: 1 | 2 | 3 | 4 | 5) => {
    const statusMapping = {
      1: '#EDEFF1',
      2: '#FFF6E5',
      3: '#EEFBE5',
      4: '#FFE9E9',
      5: '#FFE9E9',
    };

    // Return the matching status or a default value if status doesn't exist
    return statusMapping[status] || 'bg-gray-100';
  };

  const handleEventLabelText = (status: 1 | 2 | 3 | 4 | 5) => {
    const statusMapping = {
      1: '#6D788D',
      2: '#FFA500',
      3: '#34C100',
      4: '#F90004',
      5: '#F90004',
    };

    // Return the matching status or a default value if status doesn't exist
    return statusMapping[status] || 'text-gray-700';
  };

  const handleDateClick = (selected: DateSelectArg) => {
    setSelectedDate(selected);
  };

  const handleEventClick = (selected: EventClickArg) => {
    if (selected?.event?.extendedProps?.uuid) {
      router.push(`/jobs/view-job/${selected?.event?.extendedProps?.uuid}`);
    } else {
      console.error('No job ID available');
    }
  };

  const handleCloseDialog = () => {
    setNewEventTitle('');
  };

  // Triggered when the calendar view changes
  const handleDatesSet = (arg: DatesSetArg) => {
    const start = arg?.start?.toISOString();
    const end = arg?.end?.toISOString();

    onChangeDataParams({ start, end });
  };

  const handleAddEvent = (e: React.FormEvent) => {
    e.preventDefault();
    if (newEventTitle && selectedDate) {
      const calendarApi = selectedDate.view.calendar; // Get the calendar API instance.
      calendarApi.unselect(); // Unselect the date range.

      const newEvent = {
        id: `${selectedDate.start.toISOString()}-${newEventTitle}`,
        title: newEventTitle,
        start: selectedDate.start,
        end: selectedDate.end,
        allDay: selectedDate.allDay,
      };

      calendarApi.addEvent(newEvent);
      handleCloseDialog();
    }
  };

  const renderEventContent = (eventInfo: any) => {
    return (
      <div
        style={{
          display: 'inline-block',
          borderRadius: '8px',
          padding: '2px 6px',
          whiteSpace: 'nowrap',
        }}
      >
        {eventInfo?.event?.title}
      </div>
    );
  };

  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 768);
  // Dynamically change width based on screen size
  useEffect(() => {
    const updateButtonWidth = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true); // Full width on small screens
      } else {
        setIsMobile(false); // Fixed width on larger screens
      }
    };

    window.addEventListener('resize', updateButtonWidth);
    updateButtonWidth(); // Initial call to set the width
    return () => window.removeEventListener('resize', updateButtonWidth);
  }, []);

  return (
    <div id={id}>
      <div className="flex w-full rounded-md">
        <div className="w-full">
          <FullCalendar
            height={'calc(100vh - 308px)'}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: isMobile ? 'title' : 'prev,next,title',
              center: '',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }} // Set header toolbar options.
            editable={true}
            selectable={true}
            selectMirror={true} // Mirror selections visually.
            dayMaxEvents={true}
            select={handleDateClick} // Handle date selection to create new events.
            displayEventTime={false} // ✅ Hide event time for all-day events
            displayEventEnd={true} // ✅ Ensure event end is properly displayed
            eventClick={handleEventClick} // Handle clicking on events (e.g., to delete them).
            eventContent={renderEventContent}
            datesSet={handleDatesSet}
            firstDay={firstDayOfTheWeek ? firstDayOfTheWeek : 0}
            events={events}
          />
        </div>
      </div>
    </div>
  );
};

export default JobCalendar;

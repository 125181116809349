import React, { useEffect, useState } from 'react';
import {
    GoogleMap,
    InfoWindow,
    Marker,
    useLoadScript,
} from '@react-google-maps/api';
import { SingleStaffTrackingDataType } from '@/redux/thunk/staffTrackingThunk';
import { Cross } from '../../../../public/assets/icons/Cross';
import { Chip } from '@nextui-org/react';
import { mapTeamMemberType } from '@/components/team/TeamCard';

interface TrackingMapProps {
    id: string;
    staff: SingleStaffTrackingDataType[];
    selectedStaff: SingleStaffTrackingDataType
    // onLocationSelect?: (job: Job) => void;
    // company: any;
}

const mapContainerStyle = {
    width: '100%',
    height: 'calc(100vh - 308px)',
    borderRadius: '8px',
};

const TrackingMapComponent: React.FC<TrackingMapProps> = ({
    id,
    staff,
    selectedStaff,
    // onLocationSelect,
    // company,
}) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
    });

    const [hoveredPin, setHoveredPin] = useState<SingleStaffTrackingDataType | null>(null);
    const [isZoomed, setIsZoomed] = useState<boolean>(false)

    const center = selectedStaff
        ? {
            lat: +selectedStaff?.latitude,
            lng: +selectedStaff?.longitude,
        }
        : {
            lat: +staff.reduce((sum, s) => sum + (s.latitude || 0), 0) / staff.length,
            lng: +staff.reduce((sum, s) => sum + (s.longitude || 0), 0) / staff.length,
            // lat: +staff[0]?.latitude,
            // lng: +staff[0]?.longitude,
        }

    // Map zoom out and zoom in based on selected location
    useEffect(() => {
        if (selectedStaff) {
            setIsZoomed(true)
        }
        else {
            setIsZoomed(false)
        }
    }, [selectedStaff])

    // useEffect(() => {
    //     if (hoveredPin?.uuid !== selectedJob?.uuid) {
    //         setHoveredPin(null);
    //     }
    // }, [selectedJob]);

    // const handleMapPinNavigation = () => {
    //     setHoveredPin(null);

    //     if (selectedJob?.uuid) {
    //         router.push(`/jobs/view-job/${selectedJob?.uuid}`);
    //     } else {
    //         console.error('No job ID available');
    //     }
    // };

    const mapOptions = {
        styles: [
            {
                elementType: 'geometry',
                stylers: [{ color: '#f5f5f5' }],
            },
            {
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
            },
            {
                elementType: 'labels.text.fill',
                stylers: [{ color: '#616161' }],
            },
            {
                elementType: 'labels.text.stroke',
                stylers: [{ color: '#f5f5f5' }],
            },
            {
                featureType: 'administrative',
                elementType: 'geometry',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [{ color: '#eeeeee' }],
            },
            {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#757575' }],
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [{ color: '#e5e5e5' }],
            },
            {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#9e9e9e' }],
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ color: '#ffffff' }],
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#757575' }],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [{ color: '#dadada' }],
            },
            {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#616161' }],
            },
            {
                featureType: 'road.local',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#9e9e9e' }],
            },
            {
                featureType: 'transit.line',
                elementType: 'geometry',
                stylers: [{ color: '#e5e5e5' }],
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{ color: '#c9c9c9' }],
            },
            {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#9e9e9e' }],
            },
        ],
        disableDefaultUI: true, // Hide default UI elements for a cleaner look
    };

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div></div>;


    const handleSelectLocation = (staff: SingleStaffTrackingDataType) => {
        setHoveredPin(staff);
    };

    const mapLocationTrackingSvgIcon = `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.625 6.70178C3.625 9.73417 6.2778 12.2418 7.45199 13.2033C7.62004 13.3409 7.70507 13.4105 7.83044 13.4458C7.92807 13.4733 8.07175 13.4733 8.16937 13.4458C8.29498 13.4104 8.37942 13.3415 8.5481 13.2034C9.72229 12.2419 12.375 9.73445 12.375 6.70206C12.375 5.55449 11.914 4.45378 11.0936 3.64233C10.2731 2.83087 9.16037 2.375 8.00005 2.375C6.83973 2.375 5.72688 2.83094 4.90641 3.6424C4.08594 4.45385 3.625 5.55421 3.625 6.70178Z" stroke="#34C100" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.75 6.125C6.75 6.81536 7.30964 7.375 8 7.375C8.69036 7.375 9.25 6.81536 9.25 6.125C9.25 5.43464 8.69036 4.875 8 4.875C7.30964 4.875 6.75 5.43464 6.75 6.125Z" stroke="#34c100" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    `

    return (
        <GoogleMap
            id={id}
            mapContainerStyle={mapContainerStyle}
            zoom={isZoomed ? 12 : 8}
            center={center}
            options={mapOptions}
        >
            {staff?.map((staff) => {
                return (
                    <Marker
                        key={staff.id}
                        onClick={() => {
                            handleSelectLocation(staff);
                        }}
                        position={{
                            lat: +staff?.latitude,
                            lng: +staff?.longitude,
                        }}
                        icon={{
                            url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(mapLocationTrackingSvgIcon)}`,
                            scaledSize: new google.maps.Size(40, 40), // Adjust the size as needed
                        }}
                    />
                );
            })}

            {hoveredPin && (
                <InfoWindow
                    position={{
                        lat: +hoveredPin?.latitude,
                        lng: +hoveredPin?.longitude,
                    }}
                    onCloseClick={() => setHoveredPin(null)}
                    options={{
                        pixelOffset: new window.google.maps.Size(0, -30),
                    }}
                >
                    <div
                        style={{ borderRadius: 18 }}
                        className="relative max-w-xs padding-0"
                    >
                        <div className='w-full flex justify-between items-center'>
                            <Chip className='bg-light-success-100 text-light-success-default' radius="sm" variant="flat" size="sm">
                                {hoveredPin.team_member.type ? mapTeamMemberType(hoveredPin.team_member.type) : 'N/A'}
                            </Chip>
                            <button
                                id={`${id}-button-infoWindowClose`}
                                name="infoWindowClose"
                                onClick={() => setHoveredPin(null)}
                                style={{ cursor: 'pointer' }}
                            >
                                <Cross />
                            </button>
                        </div>

                        <div className='mt-4'>
                            <div className="text-body-md">{hoveredPin.team_member.full_name ? hoveredPin.team_member.full_name : 'N/A'}</div>
                            <div className="text-caption text-light-base-secondary mt-1">
                                {hoveredPin.team_member.position ? hoveredPin.team_member.position.toUpperCase() : 'N/A'}
                            </div>
                        </div>
                    </div>
                </InfoWindow>
            )}
        </GoogleMap>
    );
};

export default TrackingMapComponent;


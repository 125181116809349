import { GetAllStaffTracking } from '@/api/tracking/getAllStaffTracking';
import { GetSingleStaffTracking } from '@/api/tracking/getSingleStaffTracking';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';

// Types
export interface SingleStaffTrackingDataType {
    id: number,
    company_id: number,
    team_member_id: number,
    timestamp: string,
    latitude: number,
    longitude: number,
    created_at: string,
    updated_at: string,
    team_member: {
        uuid: string,
        first_name: string,
        last_name: string,
        full_name: string,
        email: string,
        phone: string,
        position: string,
        type: number,
        last_login: string,
        is_active: number
    }
}

// Response types
type FetchAllStaffTrackingResponse = {
    data: SingleStaffTrackingDataType[];
};

type FetchSingleStaffTrackingResponse = {
    data: SingleStaffTrackingDataType[]
}

// Argument types
type FetchSingleStaffTrackingArgs = {
    staffUuid: string
}

type FetchStaffTrackingError = string | SerializedError;

// Fetch all staff
export const fetchAllStaffTracking = createAsyncThunk<
    FetchAllStaffTrackingResponse,
    void,
    { rejectValue: FetchStaffTrackingError }
>('trackingData/fetchAllStaffTracking', async (_, { rejectWithValue }) => {
    try {
        const response = await GetAllStaffTracking();
        const data: FetchAllStaffTrackingResponse = response;
        return { data: data.data };
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
    return rejectWithValue('An unknown error occurred');
});

// Fetch single staff
export const fetchSingleStaffTracking = createAsyncThunk<
    FetchSingleStaffTrackingResponse,
    FetchSingleStaffTrackingArgs,
    { rejectValue: FetchStaffTrackingError }
>(
    'trackingData/fetchSingleStaffTracking',
    async ({ staffUuid }, { rejectWithValue }) => {
        try {
            const response = await GetSingleStaffTracking(staffUuid);
            return response;
        } catch (error: any) {
            if (error instanceof Error) {
                return rejectWithValue({ message: error.message });
            }
            // if (error.response) {
            //   const { message, messages } = error.response.data;
            //   return rejectWithValue({ message, messages });
            // }
        }
        return rejectWithValue({ message: 'An unknown error occurred' });
    },
);
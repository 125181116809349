import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSingleStaffTracking, SingleStaffTrackingDataType } from '../thunk/staffTrackingThunk';

interface SingleStaffTrackingState {
    singleStaffTracking: SingleStaffTrackingDataType[]; //Only provide one object within the array
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}

const initialState: SingleStaffTrackingState = {
    singleStaffTracking: [],
    status: 'idle',
    error: null,
    fieldErrors: {},
};

const staffTrackingSingleSlice = createSlice({
    name: 'tracking-single-staff',
    initialState,
    reducers: {
        clearSingleStaffTracking(state) {
            state.singleStaffTracking = initialState.singleStaffTracking;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSingleStaffTracking.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchSingleStaffTracking.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        data: SingleStaffTrackingDataType[];
                    }>,
                ) => {
                    state.status = 'succeeded';
                    state.singleStaffTracking = action.payload.data;
                },
            )
            .addCase(fetchSingleStaffTracking.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { clearSingleStaffTracking } = staffTrackingSingleSlice.actions;
export default staffTrackingSingleSlice.reducer;

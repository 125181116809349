import { format, parse } from 'date-fns';

const formatDateTime = (
  inputString: string,
  company: {
    date_format?: { frontend_format?: string };
    time_format?: { frontend_format?: string };
  } | null,
): string => {
  if (!inputString) return '';

  try {
    const userDateFormat =
      company?.date_format?.frontend_format || 'yyyy-MM-dd';
    const userTimeFormat = company?.time_format?.frontend_format || 'HH:mm'; // Default 24-hour format

    // Detect if input contains date, time, or both
    const hasDate = /^\d{4}-\d{2}-\d{2}/.test(inputString); // Matches "yyyy-MM-dd"
    const hasTime = /\d{2}:\d{2}(:\d{2})?$/.test(inputString); // Matches "HH:mm" or "HH:mm:ss"

    let parsedDateTime;

    if (hasDate && hasTime) {
      // Handle datetime (yyyy-MM-dd HH:mm or yyyy-MM-dd HH:mm:ss)
      const hasSeconds = inputString.length === 19; // Length of "yyyy-MM-dd HH:mm:ss"
      const formatPattern = hasSeconds
        ? 'yyyy-MM-dd HH:mm:ss'
        : 'yyyy-MM-dd HH:mm';
      parsedDateTime = parse(inputString, formatPattern, new Date());

      // Ensure time format follows user's preference
      const finalTimeFormat = hasSeconds
        ? userTimeFormat.includes('a')
          ? 'hh:mm a' // 12-hour with seconds
          : 'HH:mm' // 24-hour with seconds
        : userTimeFormat.includes('a')
          ? 'hh:mm a' // 12-hour format
          : 'HH:mm'; // 24-hour format

      return format(parsedDateTime, `${userDateFormat} ${finalTimeFormat}`);
    }

    if (hasDate) {
      // Handle date only (yyyy-MM-dd)
      parsedDateTime = parse(inputString, 'yyyy-MM-dd', new Date());
      return format(parsedDateTime, userDateFormat);
    }

    if (hasTime) {
      // Handle time only (HH:mm or HH:mm:ss)
      const hasSeconds = inputString.length === 8; // "HH:mm:ss"
      const formatPattern = hasSeconds ? 'HH:mm:ss' : 'HH:mm';
      parsedDateTime = parse(inputString, formatPattern, new Date());

      // Dynamically adjust time format based on user preference
      const finalTimeFormat = hasSeconds
        ? userTimeFormat.includes('a')
          ? 'hh:mm a' // Convert 13:00:00 to 01:00:00 PM
          : 'HH:mm' // Keep as 13:00:00
        : userTimeFormat.includes('a')
          ? 'hh:mm a' // Convert 13:00 to 01:00 PM
          : 'HH:mm'; // Keep as 13:00

      return format(parsedDateTime, finalTimeFormat);
    }

    return 'Invalid Input';
  } catch (error) {
    console.error('Error formatting input:', error);
    return 'Invalid Format';
  }
};

export default formatDateTime;

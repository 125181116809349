/* eslint-disable prettier/prettier */
// Common

import { DateValue } from '@nextui-org/react';
import { CalendarDate, CalendarDateTime } from '@internationalized/date';
import { TimeValue } from '@react-types/datepicker';
import { CreateExpenses, ExpectedLabours } from '@/helpers/types/quoteTypes';

export const formatDateTime = (dateString: string | number | Date) => {
  // Create a new Date object
  const date = new Date(dateString);

  // Check if the input string has a time component (H:i:s)
  let hasTime;
  if (typeof dateString === 'string') {
    hasTime = dateString.includes(':');
  }

  // Format date as "YYYY MMM DD"
  const year = date.getFullYear();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${year} ${month} ${day}`;

  // If time exists, format the time as well
  if (hasTime) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${formattedDate} ${hours}:${minutes}:${seconds}`;
  }

  // Return just the date if no time component is present
  return formattedDate;
};

export const formatDateTimeDT = (dateString: string | number | Date) => {
  // Create a new Date object
  const date = new Date(dateString);

  // Format date as "DD-MM-YYYY"
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  // Format time as "HH:mm"
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}`;

  return `${formattedDate} ${formattedTime}`;
};

export const formatDateTimeDMY = (dateString: string | number | Date) => {
  // Create a new Date object
  const date = new Date(dateString);

  // Format date as "DD-MM-YYYY"
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  return `${formattedDate}`;
};

export const formatDateWYMD = (dateString: string | number | Date) => {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return formattedDate;
};

export const formatDateEnquiry = (dateString: string | number | Date) => {
  // Create a new Date object
  const date = new Date(dateString);

  // Format date as "DD-MM-YYYY"
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  return `${formattedDate}`;
};

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatDateWithMidnight = (date: any): any => {
  const formattedDate = new Date(date.year, date.month - 1, date.day);
  formattedDate.setHours(0, 0, 0, 0);
  return formattedDate;
};

export const formatAddress = (address: {
  street_number: any;
  street_name: any;
  suburb: any;
  city: any;
  region: any;
  country: any;
  postal_code: any;
}) => {
  if (!address) return 'N/A';

  const {
    street_number,
    street_name,
    suburb,
    city,
    region,
    country,
    postal_code,
  } = address;

  // Build the address as a comma-separated string
  return [
    street_number,
    street_name,
    suburb,
    city,
    region,
    country,
    postal_code,
  ]
    .filter(Boolean) // Removes null, undefined, or empty fields
    .join(', ');
};

export enum FileValidation {
  COMPANY_LOGO = 'company_logo',
  EXPENSE_RECEIPT = 'expense_receipt',
  SUPPLIER_NOTE = 'supplier_note',
  QUOTE_NOTE = 'quote_note',
  ENQUIRY_NOTE = 'enquiry_note',
  SUPPLIER_IMPORT = 'supplier_import',
  CUSTOMER_IMPORT = 'customer_import',
  PRODUCT_IMPORT = 'product_import',
  PRODUCT_IMAGE = 'product_image',
  TEAM_MEMBER_IMAGE = 'team_member_image',
  INVENTORY_TRANSFER_NOTE = 'inventory_transfer_note',
  JOB_NOTE = 'job_note',
  INVENTORY_RETURN_NOTE = 'inventory_return_note',
  CUSTOMER_NOTE = 'customer_note',
  QUOTE_PRODUCT = 'quote_product',
  JOB_PRODUCT = 'job_product',
  JOB_SIGNATURE = 'job_signature',
  JOB_PHOTO = 'job_photo',
  JOB_FORM = 'job_form',
  INVOICE_SIGNATURE = 'invoice_signature',
  PURCHASE_ORDER_NOTE = 'purchase_order_note',
  CUSTOMER_PORTAL_INQUIRY = 'customer_portal_enquiry',
}


export const formatAmount = (amount: any, withDecimals: boolean = false) => {
  if (!amount) return '';

  // Step 1: Preserve leading `-`, remove other non-numeric characters except `.`
  const cleanedValue = amount.replace(/[^0-9.-]/g, '').replace(/(?!^)-/g, '');

  // Step 2: Convert to a float
  const floatValue = parseFloat(cleanedValue);
  if (isNaN(floatValue)) return '';

  // Step 3: Format with commas
  const formattedValue = floatValue.toLocaleString('en-US', {
    minimumFractionDigits: withDecimals ? 2 : 0,
    maximumFractionDigits: withDecimals ? 2 : 0,
  });

  return formattedValue;
};


/*export const formatAmount = (amount: any, withDecimals: boolean = false) => {
  if (!amount) return '';

  // Step 1: Remove non-numeric characters except for decimals
  const cleanedValue = amount.replace(/[^0-9.]/g, '');

  // Step 2: Convert to a float
  const floatValue = parseFloat(cleanedValue);
  if (isNaN(floatValue)) return '';

  // Step 3: Format with commas
  const formattedValue = floatValue.toLocaleString('en-US', {
    minimumFractionDigits: withDecimals ? 2 : 0,
    maximumFractionDigits: withDecimals ? 2 : 0,
  });

  return formattedValue;
};*/

export const formatAmountValue = (value: string) => {
  // Remove non-numeric characters (except for decimals)
  const numericValue = value.replace(/[^0-9.]/g, '');

  // Format the numeric value with commas
  return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatDateT = (date: Date | null) => {
  if (!date) return null; // Handle null or undefined dates
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(d.getDate()).padStart(2, '0');
  const hours = String(d.getHours()).padStart(2, '0'); // Get hours
  const minutes = String(d.getMinutes()).padStart(2, '0'); // Get minutes
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const formatDateTToCalenderDateTime = (
  date: TimeValue | string | null,
) => {
  if (!date) return null;

  if (typeof date !== 'string') return date;

  const dateArray = date.split(' ');

  const datePart = dateArray[0];
  const timePart = dateArray[1];

  const dateArrayPart = datePart.split('-');

  const year = dateArrayPart[0];
  const month = dateArrayPart[1];
  const day = dateArrayPart[2];

  const timeArrayPart = timePart.split(':');

  const hours = timeArrayPart[0];
  const minutes = timeArrayPart[1];

  return new CalendarDateTime(
    parseInt(year),
    parseInt(month),
    parseInt(day),
    parseInt(hours),
    parseInt(minutes),
  );
};

export const formatDateTToDateValueOnly = (
  date: DateValue | string | null | undefined,
) => {
  if (!date) return null;

  if (typeof date !== 'string') return date;

  const dateArray = date.split(' ');
  const datePart = dateArray[0];

  const dateArrayPart = datePart.split('-');

  const year = dateArrayPart[0];
  const month = dateArrayPart[1];
  const day = dateArrayPart[2];

  return new CalendarDate(parseInt(year), parseInt(month), parseInt(day));
};

export const formatTimeStringToString = (date: string) => {
  const dateArray = date.split(' ');
  const timePart = dateArray[1];
  const timeArrayPart = timePart.split(':');

  const hours = timeArrayPart[0];
  const minutes = timeArrayPart[1];

  return `${hours}:${minutes}`;
};

export const formatDateStringToString = (date: string) => {
  const dateArray = date.split(' ');
  const datePart = dateArray[0];
  const dateArrayPart = datePart.split('-');

  const year = dateArrayPart[0];
  const month = dateArrayPart[1];
  const day = dateArrayPart[2];

  return `${year}-${month}-${day}`;
};

export const urlEncode = (url: any) => {
  url = encodeURI(url);
  return url.replace(/#/g, '%23');
};

export const formatNotes = (notes: any) => {
  return notes.map((item: any) => ({
    note: item.note || '', // Set note if present, or empty if missing
    attachment: item.attachment?.link || '', // Set link if present, or empty if missing
  }));
};

export const formatToTwoDecimals = (value: string | number): string => {
  const numericValue = Number(value); // Convert string or number to a numeric value
  return numericValue.toFixed(2); // Format to two decimal places
};

export const getShortName = (name: string, maxLength = 50) => {
  if (!name) return 'Unnamed File'; // Fallback for undefined or null

  if (name.length <= maxLength) return name; // No truncation needed

  // Truncate to maxLength and ensure it ends at the last full word
  const truncated = name.substring(0, maxLength);
  const lastSpaceIndex = truncated.lastIndexOf(' ');

  // If there's a space, truncate to the last full word; otherwise, use the truncated string
  return lastSpaceIndex > -1
    ? truncated.substring(0, lastSpaceIndex) + '...'
    : truncated + '...';
};

export const ACCEPT_NOTE_FILE_TYPES = {
  'application/pdf': ['.pdf'], // PDF
  'application/msword': ['.doc'], // Word (DOC)
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], // Word (DOCX)
  'application/vnd.ms-excel': ['.xls'], // Excel (XLS)
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'], // Excel (XLSX)
  'image/*': ['.jpeg', '.jpg', '.png', '.gif'], // Images (JPEG, PNG, GIF)
  'video/*': ['.mp4', '.avi', '.mov', '.mkv', '.webm'], // Videos (MP4, AVI, MOV, MKV, WEBM)
};

export const ACCEPT_NOTE_FILE_TYPES_ARRAY = Object.keys(ACCEPT_NOTE_FILE_TYPES)
  .reduce((acc: string[], mimeType) => {
    // Expand wildcard types like 'image/*' and 'video/*'
    if (mimeType.includes('*')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const extensions = ACCEPT_NOTE_FILE_TYPES[mimeType];
      extensions.forEach((extension: string) => acc.push(`image/${extension.split('.').pop()}`)); // Convert 'image/*' to 'image/jpg', 'image/png', etc.
    } else {
      acc.push(mimeType); // Include other MIME types as they are
    }
    return acc;
  }, []);

export const getTotalLabourCost = (labourData:ExpectedLabours[]) => {
  return labourData.reduce((total, labour) => total + parseFloat(labour.cost), 0).toFixed(2);
};

export const getTotalExpensesCost = (expenses:CreateExpenses[]) => {
  return expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0).toFixed(2);
};

import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Pagination, Team, TeamMember } from '@/helpers/types';
import { GetTeamMembersData } from '@/api/team/getTeamMembers';
import { SearchTeamMembersData } from '@/api/team/searchTeamMembers';
import { DeleteTeamMemberData } from '@/api/team/deleteTeamMember';
import { GetTeamMemberById } from '@/api/team/getTeamMemberById';
import { AddTeamMember } from '@/api/team/addTeamMember';
import { UpdateTeamMember } from '@/api/team/updateTeamMember';
import { toast } from 'react-toastify';
import { sendBroadcast } from '@/utility/redux/broadcastChannel';

// Define the type for the API response
interface TeamMemberResponse {
  data: Team[];
  pagination: Pagination;
}

// Define the type for the thunk's return value
type FetchTeamMembersResponse = {
  data: Team[];
  pagination: Pagination;
};

// Define the response type for a single team member
interface TeamMemberByIdResponse {
  data: TeamMember;
}

type FetchTeamMembersError = string | SerializedError;
type SearchTeamMembersArgs = {
  searchTerm: string;
  selectedStatus: number;
  pageSize: string;
  pagination: number;
  sortBy?: string | null;
  sortOrder?: string | null;
};
type DeleteTeamMembersArgs = string;

export const fetchTeamMembers = createAsyncThunk<
  FetchTeamMembersResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchTeamMembersError; // Type for rejected value
  }
>('team/fetchTeamMembers', async (_, { rejectWithValue }) => {
  try {
    const response = await GetTeamMembersData();
    const data: TeamMemberResponse = response.data;
    return { data: data.data, pagination: data.pagination }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchTeamMembers = createAsyncThunk<
  FetchTeamMembersResponse,
  SearchTeamMembersArgs, // Updated to use the new argument structure
  {
    rejectValue: FetchTeamMembersError;
  }
>(
  'team/searchTeamMembers',
  async (
    { searchTerm, selectedStatus, pageSize, pagination, sortBy, sortOrder },
    { rejectWithValue },
  ) => {
    // Destructure the argument
    try {
      const response = await SearchTeamMembersData(
        searchTerm,
        selectedStatus,
        pageSize,
        pagination,
        sortBy,
        sortOrder,
      ); // Pass both parameters
      const data: TeamMemberResponse = response.data;
      return { data: data.data, pagination: data.pagination }; // Return formatted data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const deleteTeamMember = createAsyncThunk<
  void, // Return type of the payload creator
  DeleteTeamMembersArgs, // Argument type of the payload creator
  {
    rejectValue: FetchTeamMembersError; // Type for rejected value
  }
>(
  'team/deleteTeamMember',
  async (teamMemberUuId: string, { dispatch, rejectWithValue }) => {
    try {
      // Call the API to delete the team member
      await DeleteTeamMemberData(teamMemberUuId);

      // After successful deletion, dispatch fetchTeamMembers to refresh the list
      dispatch(fetchTeamMembers());

      // ✅ Broadcast event
      sendBroadcast({ action: 'TEAM_MEMBER_DELETED' });
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

// Thunk to fetch a team member by ID (UUID)
export const fetchTeamMemberById = createAsyncThunk<
  TeamMemberByIdResponse, // The expected return type
  string, // The expected argument type (UUID)
  {
    rejectValue: string | SerializedError; // The type for rejected value
  }
>(
  'team/fetchTeamMemberById',
  async (teamMemberUuid: string, { rejectWithValue }) => {
    try {
      return await GetTeamMemberById(teamMemberUuid); // Return the single team member data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const addTeamMember = createAsyncThunk<
  Team,
  Team,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>('team/addTeamMember', async (newTeamMember: Team, { rejectWithValue }) => {
  try {
    const response = await AddTeamMember(newTeamMember); // Function to send a POST request
    toast.success('Team member added successfully!');

    // ✅ Broadcast event
    sendBroadcast({ action: 'TEAM_MEMBER_ADDED' });

    return response.data; // Return the created team member
  } catch (error) {
    if (error.response) {
      const { message, messages } = error.response.data; // Adjust according to your API response structure
      return rejectWithValue({ message, messages });
    }

    if (error instanceof Error) {
      return rejectWithValue({ message: error.message });
    }

    return rejectWithValue({ message: 'An unknown error occurred' });
  }
});

export const updateTeamMember = createAsyncThunk<
  Team,
  Team,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'team/editTeamMember',
  async (updatedTeamMember: Team, { rejectWithValue }) => {
    try {
      const response = await UpdateTeamMember(updatedTeamMember);
      toast.success('Team member updated successfully!');

      // ✅ Broadcast event
      sendBroadcast({ action: 'TEAM_MEMBER_UPDATED' });

      return response.data; // Return the updated team member
    } catch (error) {
      if (error.response) {
        const { message, messages } = error.response.data; // Adjust according to your API response structure
        return rejectWithValue({ message, messages });
      }

      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }

      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

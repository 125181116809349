import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import { useRouter } from 'next/navigation';

// Icons
// Custom Components
import JobStatusIndicator from '@/components/jobComponents/JobCard/JobStatusIndicator';

// Helpers
import { jobCardDateFormatter } from '@/helpers/jobSchedule/jobCardDateFormatter';

// Types
import { Job } from '@/helpers/types';
import { MoreNewArrow } from '../../../public/assets/icons/MoreNewArrow';
import formatDate from '@/helpers/formatDate';
import formatDateTime from '@/helpers/formatDateTime';

interface MapProps {
  id: string;
  jobs: Job[];
  selectedJob: Job;
  onLocationSelect: (job: Job) => void;
  company: any;
}

const mapContainerStyle = {
  width: '100%',
  height: 'calc(100vh - 308px)',
  borderRadius: '8px',
};

const MapComponent: React.FC<MapProps> = ({
  id,
  jobs,
  selectedJob,
  onLocationSelect,
  company,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
  });

  const router = useRouter();

  const [hoveredPin, setHoveredPin] = useState<Job | null>(null);

  useEffect(() => {
    if (hoveredPin?.uuid !== selectedJob?.uuid) {
      setHoveredPin(null);
    }
  }, [selectedJob]);

  const handleMapPinNavigation = () => {
    setHoveredPin(null);

    if (selectedJob?.uuid) {
      router.push(`/jobs/view-job/${selectedJob?.uuid}`);
    } else {
      console.error('No job ID available');
    }
  };

  const mapOptions = {
    styles: [
      {
        elementType: 'geometry',
        stylers: [{ color: '#f5f5f5' }],
      },
      {
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#f5f5f5' }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#dadada' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#c9c9c9' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }],
      },
    ],
    disableDefaultUI: true, // Hide default UI elements for a cleaner look
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div></div>;

  const center = selectedJob?.customer_site_address
    ? {
        lat: +selectedJob?.customer_site_address?.latitude,
        lng: +selectedJob?.customer_site_address?.longitude,
      }
    : {
        lat: +jobs[0]?.customer_site_address?.latitude,
        lng: +jobs[0]?.customer_site_address?.longitude,
      };

  const handleSelectLocation = (job: Job) => {
    onLocationSelect(job);
    setHoveredPin(job);
  };

  return (
    <GoogleMap
      id={id}
      mapContainerStyle={mapContainerStyle}
      zoom={12}
      center={center}
      options={mapOptions}
    >
      {jobs?.map((job) => {
        // Dynamically set the color based on job status
        const getColorByStatus = (status: any) => {
          switch (status) {
            case 1:
              return '#6D788D'; // Gray for draft
            case 2:
              return '#FFA500'; // Orange for in progress
            case 3:
              return '#34C100'; // Green for completed
            case 4:
              return '#F90004'; // Red for closed
            case 5:
              return '#F90004'; // Red for canceled
            default:
              return '#838282'; // Grey for other statuses
          }
        };

        const svgIcon = `
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_645_2536)">
        <rect x="5" y="4" width="30" height="30" rx="15" fill="white"/>
        <g clip-path="url(#clip0_645_2536)">
          <path d="M13.75 24.0001H15M15 24.0001H18.75M15 24.0001V18.6577C15 18.3237 15 18.1567 15.0406 18.0013C15.0766 17.8636 15.1361 17.7332 15.2161 17.6155C15.3063 17.4826 15.4317 17.3724 15.683 17.1525L18.684 14.5266C19.1499 14.1189 19.3829 13.915 19.6453 13.8374C19.8766 13.769 20.1232 13.769 20.3546 13.8374C20.6172 13.9151 20.8505 14.1191 21.3171 14.5275L24.3171 17.1525C24.5685 17.3724 24.6939 17.4826 24.7841 17.6155C24.8641 17.7332 24.9231 17.8636 24.9591 18.0013C24.9997 18.1567 25 18.3237 25 18.6577V24.0001M18.75 24.0001H21.25M18.75 24.0001V21.5001C18.75 20.8098 19.3096 20.2501 20 20.2501C20.6904 20.2501 21.25 20.8098 21.25 21.5001V24.0001M21.25 24.0001H25M25 24.0001H26.25" 
            stroke="${getColorByStatus(job.status)}" 
            stroke-width="2" 
            stroke-linecap="round" 
            stroke-linejoin="round"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_d_645_2536" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="2.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_645_2536"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_645_2536" result="shape"/>
        </filter>
        <clipPath id="clip0_645_2536">
          <rect width="15" height="15" fill="white" transform="translate(12.5 11.5)"/>
        </clipPath>
      </defs>
    </svg>
  `;

        return (
          <Marker
            key={job.uuid}
            onClick={() => {
              handleSelectLocation(job);
            }}
            position={{
              lat: +job?.customer_site_address?.latitude,
              lng: +job?.customer_site_address?.longitude,
            }}
            icon={{
              url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgIcon)}`,
              scaledSize: new google.maps.Size(40, 40), // Adjust the size as needed
            }}
          />
        );
      })}

      {hoveredPin && (
        <InfoWindow
          position={{
            lat: +hoveredPin?.customer_site_address?.latitude,
            lng: +hoveredPin?.customer_site_address?.longitude,
          }}
          onCloseClick={() => setHoveredPin(null)}
          options={{
            pixelOffset: new window.google.maps.Size(0, -30),
          }}
        >
          <div
            style={{ borderRadius: 18 }}
            className="relative max-w-xs padding-0"
          >
            {/* Custom Arrow Button */}
            {/* <button
              id={`${id}-button-infoWindowClose`}
              name="infoWindowClose"
              className="absolute top-0 right-0 bg-transparent border-none"
              onClick={() => setHoveredPin(null)}
              style={{ cursor: 'pointer' }}
            >
              <Cross />
            </button> */}
            <div className="w-full flex justify-between items-center">
              <div>
                <JobStatusIndicator
                  id={`${id}-jobStatusIndicator`}
                  status={hoveredPin?.status}
                />
              </div>
              <button
                id={`${id}-button-viewJob`}
                name="viewJob"
                type="button"
                className="bg-transparent border-none"
                onClick={handleMapPinNavigation}
                style={{ cursor: 'pointer' }}
              >
                <MoreNewArrow />
              </button>
            </div>

            <div className="flex pt-4">
              <div className="text-caption text-light-base-secondary pr-1">
                # {hoveredPin?.job_number}
              </div>
              <div className="text-caption text-light-base-secondary">|</div>
              <div className="text-caption text-light-base-secondary pl-1">
                {/*{jobCardDateFormatter({ date: hoveredPin?.start_date })}*/}
                {formatDateTime(hoveredPin?.start_date, company)}
              </div>
            </div>
            <div className="text-body-md">{hoveredPin?.title}</div>

            <div className="border-t-1 mt-2 pt-2">
              {hoveredPin?.assigned_members
                ?.slice(0, 2)
                .map((member: any, index: any) => (
                  <div key={index}>
                    <div className="pb-1">{member.full_name}</div>
                  </div>
                ))}
              {hoveredPin?.assigned_members?.length > 2 && (
                <div className="text-caption text-light-base-secondary">
                  +{hoveredPin?.assigned_members?.length - 2}
                </div>
              )}
            </div>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default MapComponent;

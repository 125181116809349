import React from 'react';
import { Card, CardBody, Chip } from '@nextui-org/react';

// Types
import { Team } from '@/helpers/types';

interface TeamMemberCardProps {
    id: string;
    teamMember: Team;
    selectedTeamMemberUUID?: string;
    onClick: (team: Team) => void;
    // company: any;
}

export const mapTeamMemberType = (type: number) => {
    switch (type) {
        case 1:
            return 'Administration'
        case 2:
            return 'Tradee'
        case 3:
            return 'Management'
    }
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
    id,
    teamMember,
    selectedTeamMemberUUID,
    onClick,
    // company,
}) => {

    const handleTeamMemberCardClick = () => {
        onClick(teamMember);
    };

    return (
        <Card
            id={id}
            radius="sm"
            shadow="none"
            className={`border-2 ${teamMember?.uuid === selectedTeamMemberUUID ? 'border-light-base-primary' : ''}`}
        >
            <CardBody
                onClick={handleTeamMemberCardClick}
                className="p-2 lg:p-4 cursor-pointer"
            >
                <div>
                    <Chip id={id} className='bg-light-success-100 text-light-success-default' radius="sm" variant="flat" size="sm">
                        {teamMember.type ? mapTeamMemberType(teamMember.type) : 'N/A'}
                    </Chip>
                </div>
                <div className="pt-4">
                    <div className="text-caption text-light-base-secondary pr-1">
                        {teamMember.position ? teamMember.position.toUpperCase() : 'N/A'}
                    </div>
                </div>
                <div className="text-body-sm lg:text-body-xl">{teamMember.full_name ? teamMember.full_name : 'N/A'}</div>
            </CardBody>
        </Card>
    );
};

export default TeamMemberCard;
